import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './createContext';
import { StripeProvider } from 'react-stripe-elements';

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
class AppProvider extends Component {
  componentDidMount() {
    // Create Stripe instance in componentDidMount (which only fires in DOM environment)
    this.setState({
      stripe: window.Stripe('pk_live_zPlit4W7oMDyUPcpR79ZH9pc'),
    });
  }

  togglePrice = () => {
    this.setState(prevState => ({
      monthlyPrice: !prevState.monthlyPrice,
    }));
  };

  state = {
    open: false,
    showModal: () => this.setState({ open: true }),
    hideModal: () => this.setState({ open: false }),
    monthlyPrice: false,
    togglePrice: this.togglePrice,
    stripe: null,
  };

  render() {
    return (
      <Provider value={this.state}>
        <StripeProvider stripe={this.state.stripe}>
          {this.props.children}
        </StripeProvider>
      </Provider>
    );
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
